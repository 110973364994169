<!--
 * @Author: Akarichan
 * @LastEditors: Akarichan
-->
<template>
  <div class="home">
    <ToreSimulation />
  </div>
</template>

<script>
// @ is an alias to /src
import ToreSimulation from '@/components/ToreSimulation.vue'

export default {
  name: 'Home',
  components: {
    ToreSimulation
  }
}
</script>
